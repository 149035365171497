import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { BACKEND_URL } from "../env";

export const AppContext = createContext();

export const withAppContext = (Component) => (props) =>
  (
    <AppContext.Consumer>
      {(value) => <Component {...value} {...props} />}
    </AppContext.Consumer>
  );

const AppProvider = ({ children }) => {
  const [AllCategories, setAllCategories] = useState([]);
  const [AllCategoriesWithImages, setAllCategoriesWithImages] = useState([]);
  const [AllSubCategories, setAllSubCategories] = useState([]);
  const [AllMultipleProducts, setAllMultipleProducts] = useState([]);
  const [LoadAllMultipleProducts, setLoadAllMultipleProducts] = useState(true);
  const [SubTotalPrice, setSubTotalPrice] = useState(0);
  const [ShippingCharges, setShippingCharges] = useState([]);
  const [Discount, setDiscount] = useState(0);
  const [Quantity, setQuantity] = useState([]);
  const [SliderData, setSliderData] = useState([]);
  const [CustomerInfo, setCustomerInfo] = useState({});
  const [CustomerSale, setCustomerSale] = useState([]);
  const [AllColors, setAllColors] = useState([]);
  const [AllSizes, setAllSizes] = useState([]);
  const [WishList, setWishList] = useState([]);
  const [shoppingCartLoader, setShoppingCartLoader] = useState(false);
  const [BannerImages, setBannerImages] = useState([]);
  const [BannerResImages, setBannerResImages] = useState([]);
  const [ItemsQuantity, setItemsQuantity] = useState([]);
  const [productWeight, setProductWeight] = useState("0");

  function SliderImages() {
    axios
      .get(`${BACKEND_URL}/GetAllBannerImages`)
      .then((res) =>
        setBannerImages(
          res.data.sort((a, b) => a?.customIndex - b?.customIndex)
        )
      )
      .catch((error) => console.log(error));
  }
  function SliderResImages() {
    axios
      .get(`${BACKEND_URL}/GetAllResBannerImages`)
      .then((res) =>
        setBannerResImages(
          res.data.sort((a, b) => a?.customIndex - b?.customIndex)
        )
      )
      .catch((error) => console.log(error));
  }
  const LoggedInCustomer = () => {
    // console.log(localStorage.getItem("CustomerId"));
    axios
      .get(
        `${BACKEND_URL}/GetCustomerInfo/${JSON.parse(
          localStorage.getItem("CustomerId")
        )}`,
        {
          headers: {
            token: `Bearer ${JSON.parse(
              localStorage.getItem("CustomerToken")
            )}`,
          },
        }
      )
      .then((res) => {
        setCustomerInfo(res.data[0]);
        // console.log(res)
      })
      .catch((res) => console.log(res, "catch"));
  };
  const GetData = () => {
    if (localStorage.getItem("AddToWishList")) {
      setWishList(JSON.parse(localStorage.getItem("AddToWishList")));
    } else {
      setWishList([]);
    }
  };
  const AddToWishList = (Product) => {
    if (localStorage.getItem("AddToWishList")) {
      let data = JSON.parse(localStorage.getItem("AddToWishList"));
      if (data.filter((item) => item.id === Product?.id).length) {
        // alert("Al");
        if (data?.length) {
          // localStorage.removeItem("AddToWishList");
          GetData();
        } else {
          let index = data.indexOf(
            data.filter((item) => item.id === Product?.id)[0]
          );
          data.splice(index, 1);
          localStorage.setItem("AddToWishList", JSON.stringify(data));
          GetData();
        }
      } else {
        data.push({
          id: Product?.id,
        });
        localStorage.setItem("AddToWishList", JSON.stringify(data));
        GetData();
      }
    } else {
      let obj = [
        {
          id: Product?.id,
        },
      ];
      // console.log(Product);
      localStorage.setItem("AddToWishList", JSON.stringify(obj));
      GetData();
    }
  };
  function GetAllCategories() {
    axios
      .get(`${BACKEND_URL}/GetAllCategories`)
      .then((res) => {
        setAllCategories(res.data);
      })
      .catch((res) => console.log(res, "catch"));
  }
  function GetCustomerSale() {
    axios
      .get(
        `${BACKEND_URL}/GetCustomerSale/${JSON.parse(
          localStorage.getItem("CustomerId")
        )}`
      )
      .then((res) => {
        setCustomerSale(res?.data);
        // console.log(res?.data,"res?.data");
      })
      .catch((res) => console.log(res, "CATCH_Orders"));
  }
  function GetAllCategoriesWithImages() {
    axios
      .get(`${BACKEND_URL}/GetAllCategoriesWithImages`)
      .then((res) => {
        setAllCategoriesWithImages(res.data);
        // console.log(res?.data,"BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB");
      })
      .catch((res) => console.log(res, "catch"));
  }
  function GetAllShippingCharges() {
    axios
      .get(`${BACKEND_URL}/GetAllShippingCharges`)
      .then((res) => {
        // console.log(res, "BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB");
        setShippingCharges(res?.data);
      })
      .catch((res) => console.log(res, "catch"));
  }
  function GetColors() {
    axios
      .get(`${BACKEND_URL}/GetAllColors`)
      .then((res) => {
        setAllColors(res.data?.data);
        // console.log(res?.data,"BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB");
      })
      .catch((res) => console.log(res, "catch"));
  }
  function GetSizes() {
    axios
      .get(`${BACKEND_URL}/GetAllSizes`)
      .then((res) => {
        setAllSizes(res.data?.data);
        // console.log(res?.data,"BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB");
      })
      .catch((res) => console.log(res, "catch"));
  }
  function GetAllMultipleProducts() {
    setShoppingCartLoader(true);
    let data = JSON.parse(localStorage.getItem("AddToCart"));
    let obj = [];
    data?.map((e, i) => {
      obj.push(e?.itemid);
    });
    let arr = obj.join("','");
    let arr2 = "'" + arr + "'";
    var config = {
      method: "post",
      url: `${BACKEND_URL}/getMultipleItems`,
      data: { ids: arr2 },
    };
    axios(config)
      .then(function (res) {
        console.log(res?.data);
        let obj2 = [];
        // console.log(res.data, "res.data");
        data?.map((e, i) => {
          // if (e.itemid === res?.data[i]?.id) {
          let filter = res.data.filter((prod) => prod?.id === e?.itemid);
          obj2.push({ Quantity: e?.quantity, ...filter[0] });
          // }
        });
        // setLoadAllMultipleProducts(false);
        setAllMultipleProducts(obj2);
        getMultipleProductQuantity(obj2);
      })
      .catch(function (error) {
        // setLoadAllMultipleProducts(false);
        console.log(error);
      });
  }
  function getMultipleProductQuantity(obj) {
    let data = JSON.parse(localStorage.getItem("AddToCart"));
    let Quantityitems = [];
    let AllQuantity = 0;
    data?.map((e, i) => {
      if (obj.filter((item) => item?.id === e?.itemid).length) {
        Quantityitems.push(e?.quantity);
        AllQuantity = AllQuantity + e?.quantity;
      }
    });
    setItemsQuantity(AllQuantity);
    setLoadAllMultipleProducts(!LoadAllMultipleProducts);
    setQuantity(Quantityitems);
    GetSubTotal(obj, Quantityitems);
  }
  function GetSubTotal(obj, quantity) {
    let data = "";
    let disc = "";
    obj?.map((e, i) => {
      let cost =
        e?.Discount !== 0
          ? (e?.Discount / 100) * Number(e?.price)
          : Number(e?.price);
      // let price = e?.price - cost;
      let price =
        e?.Discount !== 0 ? Number(e?.price) - Number(cost) : Number(e?.price);
      data = quantity[i] * Number(price) + Number(data);
      // let discount =
      //   e?.Discount !== 0 ? (e?.Discount / 100) * e?.price : e?.price;
      // console.log(discount, "discount");
      // data =
      //   data !== "" ? Quantity[i] * discount + data : Quantity[i] * discount;
      // disc = disc === "" ? e?.Discount : disc + e?.Discount;
      // console.log(data, "data");
    });
    // setDiscount(disc);
    setShoppingCartLoader(false);
    setSubTotalPrice(Number(data));
  }
  function CalculateShipping(
    ItemsQuantity,
    Location,
    suitsPieces,
    productWeight
  ) {
    let shippingcharges = 5;
    if (Location?.City?.toLowerCase() === "karachi") {
      if (suitsPieces?.length) {
        shippingcharges = 500;
      }
    } else {
      shippingcharges = (productWeight - 1) * 80 + 200;
      // let array = [];
      // suitsPieces?.map((a, i) => {
      //   if (!array.includes(a?.piece)) {
      //     let filter = ShippingCharges.filter(
      //       (ev) =>
      //         ev?.piece === a?.piece &&
      //         (ev?.quantity === Number(a?.quantity) ||
      //           ev?.quantity < Number(a?.quantity))
      //     );
      //     let sorted = filter.sort((a, b) => a?.quantity - b?.quantity);
      //     shippingcharges = shippingcharges + sorted[sorted?.length - 1]?.price;
      //     array.push(a?.piece);
      //   }
      // });
    }
    // console.log(sorted, "sorted");
    // if (Location?.City?.toLowerCase() === "karachi") {
    //   ShippingCharges =
    //     ItemsQuantity === 1
    //       ? 200
    //       : ItemsQuantity > 1
    //       ? 100 + ItemsQuantity * 100
    //       : 0;
    // } else {
    //   ShippingCharges =
    //     ItemsQuantity === 1
    //       ? 250
    //       : ItemsQuantity > 1
    //       ? 150 + ItemsQuantity * 100
    //       : 0;
    // }
    return Number(shippingcharges);
  }
  function GetAllSubCategories() {
    axios
      .get(`${BACKEND_URL}/GetAllSubCategories`)
      .then((res) => {
        setAllSubCategories(res.data);
      })
      .catch((res) => console.log(res, "catch"));
  }
  function GetSliderProducts() {
    axios
      .get(`${BACKEND_URL}/GetSliderProducts`)
      .then((res) => {
        // console.log(";'", res?.data);
        setSliderData(res?.data);
      })
      .catch((res) => console.log(res, "catch"));
  }
  useEffect(() => {
    // if (localStorage.getItem("CustomerToken")) {
    // 	LoggedInCustomer();
    // }
    SliderImages();
    SliderResImages();
    GetSliderProducts();
    GetAllCategoriesWithImages();
    GetAllShippingCharges();
    GetAllCategories();
    GetAllSubCategories();
    if (localStorage.getItem("AddToCart")) {
      GetAllMultipleProducts();
      // getMultipleProductQuantity();
      // GetSubTotal();
    }
  }, []);
  useEffect(() => {
    if (
      localStorage.getItem("CustomerId") &&
      localStorage.getItem("CustomerToken")
    ) {
      LoggedInCustomer();
    }
  }, [localStorage.getItem("CustomerId")]);

  return (
    <AppContext.Provider
      value={{
        productWeight,
        setProductWeight,
        CustomerInfo,
        GetSliderProducts,
        LoggedInCustomer,
        AllCategories,
        GetAllCategories,
        AllSubCategories,
        GetAllSubCategories,
        AllCategoriesWithImages,
        SliderData,
        AllMultipleProducts,
        GetAllMultipleProducts,
        Quantity,
        getMultipleProductQuantity,
        SubTotalPrice,
        GetSubTotal,
        setQuantity,
        setSubTotalPrice,
        setAllMultipleProducts,
        setDiscount,
        Discount,
        GetCustomerSale,
        CustomerSale,
        GetColors,
        AllColors,
        GetSizes,
        AllSizes,
        AddToWishList,
        WishList,
        GetData,
        BannerImages,
        BannerResImages,
        LoadAllMultipleProducts,
        shoppingCartLoader,
        ItemsQuantity,
        setItemsQuantity,
        CalculateShipping,
        setItemsQuantity,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
